<template>
  <div class="main">
    <TableMain
      tableTitle="Evaluaciones Médicas"
      class="mx-4 mt-4 mb-5"
      actionButtonName="Registrar Evaluación Médica"
      :actionButtonLink="'create/' + $route.params._id"
      :headers="['#', 'Cédula', 'Nombre', 'Fecha del Examen', 'Concepto']"
      :data="medicalEvaluationList"
      :editOption="true"
      :deleteOption="true"
      :downloadOption="true"
      @editAction="editEvent($event)"
      @deleteAction="deleteEvent($event)"
      @downloadAction="downloadEvent($event)"
    >
    </TableMain>
    <Legal></Legal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";
import TableMain from "@/components/layout/Table";

import { prettyDate, titleCase } from "@/modules/tools";

export default {
  components: { Legal, TableMain },
  beforeCreate() {
    const store = new Storage();
    store.getData("branch").then(branchData => {
      branchData.forEach(branch => {
        if (this.$route.params._id === branch._id) {
          store.getData("city", branch.city).then(cityData => {
            localStorage.setItem(
              "currentLocation",
              "Empleados / Evaluaciones Médicas / " + cityData.name
            );
          });
        }
      });
    });
  },
  methods: {
    editEvent(_id) {
      this.$router.push({
        name: "Employee Evaluation Edit",
        params: {
          _id: _id
        }
      });
    },
    downloadEvent(_id) {
      const store = new Storage();
      store.getData("medicalEvaluation", _id).then(medicalEvaluation => {
        if (medicalEvaluation.certificate) {
          store
            .getData("file", medicalEvaluation.certificate)
            .then(fileData => {
              const URL =
                store.baseURL +
                "/optima/file/download?where=_id=" +
                fileData._id;
              axios.get(URL, { withCredentials: true }).then(res => {
                window.open(res.data.body.URL);
              });
            });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Certificado de Aptitud Laboral",
            text: "No se halló el certificado para esta evaluación médica"
          });
        }
      });
    },
    deleteEvent(_id) {
      const store = new Storage();
      store.getData("medicalEvaluation", _id).then(medicalEvaluation => {
        Swal.fire({
          title: "¿Eliminar Evaluación Médica?",
          showCancelButton: true,
          confirmButtonText: `Eliminar`,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#dc3545",
          cancelButtonColor: "#6c757d"
        }).then(result => {
          if (result.isConfirmed) {
            Swal.fire({
              icon: "warning",
              title: "Eliminando",
              html:
                "Por favor espere mientras se elimnina la evaluación médica</b> " +
                '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              stopKeydownPropagation: true
            });
            if (medicalEvaluation.certificate) {
              store
                .deleteData("optima", "file", medicalEvaluation.certificate)
                .then(() => {
                  store
                    .deleteData("optima", "medicalEvaluation", _id)
                    .then(() => {
                      Swal.fire({
                        icon: "success",
                        title: "Evaluación Médica Eliminada",
                        text: "Todos los datos han sido sincronizados"
                      }).then(() => {
                        this.$router.go();
                      });
                    });
                });
            } else {
              store.deleteData("optima", "medicalEvaluation", _id).then(() => {
                Swal.fire({
                  icon: "success",
                  title: "Evaluación Médica Eliminada",
                  text: "Todos los datos han sido sincronizados"
                }).then(() => {
                  this.$router.go();
                });
              });
            }
          }
        });
      });
    }
  },
  created() {
    const store = new Storage();
    store.getData("medicalEvaluation").then(medicalEvaluationList => {
      store.getData("employee").then(employeeList => {
        medicalEvaluationList.forEach(medicalEvaluation => {
          if (medicalEvaluation.branch === this.$route.params._id) {
            prettyDate(medicalEvaluation.date).then(date => {
              const data = [];
              data.push(medicalEvaluation._id);
              employeeList.forEach(async employee => {
                if (employee._id === medicalEvaluation.employee) {
                  const i = employee.ID.toString();
                  const j = i.length > 3 ? i.length % 3 : 0;
                  const ID =
                    (j ? i.substr(0, j) + "." : "") +
                    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + ".");
                  data.push(ID);
                  data.push(employee.name);
                }
              });
              data.push(date);
              data.push(titleCase(medicalEvaluation.concept));
              this.medicalEvaluationList.push(data);
            });
          }
        });
      });
    });
  },
  data() {
    return {
      medicalEvaluationList: [],
      branchOptions: {}
    };
  },
  name: "Employee Evaluation Branch"
};
</script>
